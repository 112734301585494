<template>
  <b-overlay :show="loading">
    <div class="mt-md-5 px-md-2">
      <div>
        <list-candidates
          v-if="candidates.length > 0"
          :items="candidates"
          :per-page="perPage"
          :total-records="totalRecords"
          :current-page.sync="currentPage"
          detail-page="single-candidate"
        />

        <div v-else-if="!loading">
          <h3>No candidates available.</h3>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, 
  BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination, BButton
} from 'bootstrap-vue'

import { get, debounce } from "lodash";
import { kFormatter } from '@core/utils/filter';

import ListCandidates from "@/@core/components/shared/candidates/ListCandidates.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue';

const watchHandler = {
  handler(){
    this.fetch()
  }, 
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetch()
  },
  immediate: false
}

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BLink,
    BMedia,
    BBadge,
    BAvatar,
    BButton,
    BOverlay,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BMediaBody,
    BMediaAside,
    BInputGroup,
    BPagination,
    ListCandidates,
    BInputGroupAppend,
    ContentWithSidebar,
  },
  data() {
    return {
      candidates: [],
      debounceFetch: null,

      // perPage: 50,
      // currentPage: 1,
      loading: true,
      user: '',
      recent_loading: false
    }
  },
  computed: {
    dataMeta(){
      const localItemsCount = this.loans.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    user: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created() {
    this.debounceFetch = debounce(this.fetch, 500);

    const {
      user_id,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.user = user_id;
    this.perPage = +limit;
    this.currentPage = +page;
  },
  methods: {
    async fetch() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          user_id: this.user,
          page: this.currentPage,
        }
        await this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().sharedService.fetchCandidates(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.candidates = data;
        // this.candidates = [];
        // eslint-disable-next-line no-plusplus
        // for (let i = 0; i < 10; i++){
        //   this.candidates.push(data[0])
        // }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
}
</script>
